import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {Box, ButtonGroup} from "@mui/material";
import styled from "@emotion/styled";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  if (!props.clickedItem) return;
  let item = props.clickedItem;
  let quantity = 0;
  let itemRequest = props.requests.find(r => r.item._id === item._id);
  if (itemRequest) {
    quantity = itemRequest.quantity;
  }

  const QuantityButton = styled(Box)`
    background: black;
    color: white;
    padding: 16px;
    font-weight: bold;
    border-radius: 5px;
  `

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle>{item.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {item.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup sx={{mr: 1, boxShadow: "none"}} variant="contained">
            {
              quantity === 0 ?
                <QuantityButton style={{color: "grey"}} onClick={() => props.removeItemUnit({_id: item._id, name: item.name})}>-</QuantityButton>
                :
                <QuantityButton onClick={() => props.removeItemUnit({_id: item._id, name: item.name})}>-</QuantityButton>
            }
            <Box sx={{ p: 2, background: "white", color: "black", fontWeight: "bold" }}>
              <span>{quantity}</span>
            </Box>
            <QuantityButton onClick={() => props.addItemUnit({_id: item._id, name: item.name})}>+</QuantityButton>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
}
