import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import styled from "@emotion/styled";
import {Alert, Snackbar, TextField} from "@mui/material";
import axios from "axios";
import {useState} from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssistanceDialog(props) {

  const [message, setMessage] = useState("");

  const BlackButton = styled.div`
    background: black;
    color: white;
    padding: 10px 12px;
    font-weight: bold;
    border-radius: 5px;
  `

  const GreyButton = styled.div`
    background: #eee;
    color: black;
    padding: 10px 12px;
    font-weight: bold;
    border-radius: 5px;
  `

  const [openAlert, setOpenAlert] = React.useState(false);

  const updateStatusToAssistance = () => {
    const configuration = {
      method: "PATCH",
      url: `https://neomenu-backend.fly.dev/restaurants/${props.restaurantId}/tables/${props.tableId}/assistance`,
      data: {
        accessId: props.accessId,
        message: message,
      },
    };
    return axios(configuration)
      .then(result => {
      })
      .catch(e => {
        console.log(e.toString())
      });
  }

  const handleClick = () => {
    updateStatusToAssistance();
    props.handleClose();
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle>Assistance</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Optionally, add a short message:
            <TextField
              fullWidth={true}
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <GreyButton onClick={props.handleClose}>Cancel</GreyButton>
          <BlackButton onClick={handleClick}>Call waiter</BlackButton>
        </DialogActions>
      </Dialog>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%', fontWeight: 'bold' }}>
          A waiter has been notified.
        </Alert>
      </Snackbar>
    </div>
  );
}
