import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {Alert, Grid, Snackbar, Stack, TextField} from "@mui/material";
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDialog(props) {

  const [comment, setComment] = React.useState("");

  const createOrder = () => {
    axios.post(`https://neomenu-backend.fly.dev/restaurants/${props.restaurantId}/orders`, {
      requests: props.requests,
      accessId: props.accessId,
      tableId: props.tableId,
      comment: comment,
    })
      .then(function (response) {
        handleClick(); // success alert
        console.log(response);
        props.handleClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [openAlert, setOpenAlert] = React.useState(false);

  const handleClick = () => {
    props.handleClose();
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{position: 'relative'}}>
          <Toolbar sx={{background: "black"}}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
              Your order
            </Typography>
            <Button sx={{background: "#eee", color: "black"}} variant="contained" onClick={() => {
              createOrder();
              props.clearRequests();
            }}>
              <CheckIcon/>
            </Button>
          </Toolbar>
        </AppBar>
        <Stack px={4} sx={{marginTop: "20px"}}>
          <TextField
            label="Add an observation"
            fullWidth
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Stack>
        <Stack px={4} spacing={1}>
          <Divider sx={{width: "100%", margin: "12px 0"}}/>
          {props.requests.map(r => {
            return <Grid container style={{marginTop: 0}} direction="row"
                         justify="flex-end"
                         alignItems="center">
              <div style={{
                fontSize: "20px",
                textAlign: "center",
                padding: "5px 12px",
                background: "#eee"
              }}>{r.quantity}</div>
              <div style={{fontSize: "20px", padding: "5px"}}>{r.item.name}</div>
              <Divider sx={{width: "100%", margin: "12px 0"}}/>
            </Grid>
          })}
        </Stack>
      </Dialog>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success" sx={{width: '100%', fontWeight: 'bold'}}>
          Your order has been sent.
        </Alert>
      </Snackbar>
    </div>
  );
}
