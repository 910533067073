import './App.css';
import axios from "axios";
import {useEffect, useState} from "react";
import {Box, CircularProgress, Divider, Grid, Stack} from "@mui/material";
import styled from "@emotion/styled";
import AlertDialogSlide from "./AddItemDialog";
import * as React from "react";
import OrderDialog from "./OrderDialog";
import logo from './img/logo.png';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import RoomServiceOutlinedIcon from '@mui/icons-material/RoomServiceOutlined';
import AssistanceDialog from "./AssistanceDialog";
import PaymentDialog from "./PaymentDialog";
import {useLocation} from "react-router-dom";

export default function GuestMenu() {

  const [accessId, setAccessId] = useState(null);
  const [restaurantId, setRestaurantId] = useState(null);
  const [tableId, setTableId] = useState(null);
  const [menu, setMenu] = useState([]);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  const getMenu = async (restaurantId) => {
    axios.get(`https://neomenu-backend.fly.dev/restaurants/${restaurantId}/categories`)
      .then(categories => {
        let promises = [];
        categories.data.data.map(c => {
          promises.push(new Promise((resolve, reject) => {
            axios.get(`https://neomenu-backend.fly.dev/restaurants/${restaurantId}/categories/${c._id}/items`)
              .then(items => {
                resolve({category: c, items: items.data.data});
              })
              .catch(e => {
                reject(e);
              });
          }));
        });
        Promise.all(promises)
          .then(items => {
            setMenu(items);
          }).finally(() => setLoading(false));
      });
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const restaurantId = url.pathname.split("/")[2];
    setRestaurantId(restaurantId);
    getMenu(restaurantId);
  }, []);

  const ItemName = styled.span`
    color: black;
    font-size: 17px;
    font-weight: bold;
  `
  const ItemDescription = styled.span`
    color: darkgrey;
    font-size: 14px;
    margin-top: 10px;
  `
  const ItemPrice = styled.span`
    color: black;
    margin-top: 10px;
  `

  const CategoryTitle = styled.p`
    color: black;
    font-weight: bold;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 10px;
  `

  if (loading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{minHeight: '100vh'}}
      >
        <Grid item xs={3}>
          <CircularProgress style={{color: "#1ea59a"}}/>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <div id="header">
        <Grid
          justifyContent="space-between"
          container
          alignItems="center"
          justify="center"
          px={2}
          py={1}
        >
          <Grid item>
            <img src={logo} alt="logo" height={18} style={{marginTop: "6px"}}/>
          </Grid>


          <Grid item
                style={{
                  width: "50%",
                  background: "#FFCA00",
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  padding: "6px"
                }}>
            Viewing as guest
          </Grid>
        </Grid>

        <Divider/>
        <Stack style={{
          color: "black",
          fontSize: "18px",
          display: 'flex',
          flexDirection: 'row',
          padding: "0 5px",
          maxWidth: "100%",
          overflowY: "none",
          overflowX: "auto"
        }}>
          {menu.map((entry, idx) => <><span
            style={{
              margin: "0 14px",
              height: '38px',
              display: 'inline-flex',
              alignItems: 'center',
              whiteSpace: "nowrap"
            }}
            onClick={() => {
              // Clicking an already selected category does nothing
              // TODO: do this properly, allow multiple clicks on the same category
              if (window.location.hash.replace('#', '') !== entry.category.name) {
                // Clicking a category scrolls to it
                window.location.hash = entry.category.name;
                window.scrollBy(0, -96);
              }
            }}>{entry.category.name}</span>
            {idx === menu.length - 1 ? '' : <Divider orientation="vertical" variant="middle" flexItem/>}</>
          )}
        </Stack>
        <Divider/>
      </div>

      <div id="items">
        <Grid container px={2} sx={{mt: "8px"}}>
          {menu.map(entry => {
            return <div style={{width: "100%", marginBottom: "15px"}}>
              <CategoryTitle id={entry.category.name}>{entry.category.name}</CategoryTitle>
              {entry.items.map((item, idx) => {
                return (
                  <>
                    <Grid container>
                      <Grid item xs={6}>
                        <Stack>
                          <ItemName>{item.name}</ItemName>
                          {item.description ? <ItemDescription>{item.description}</ItemDescription> : ''}
                          <ItemPrice>${item.price.toFixed(2)}</ItemPrice>
                        </Stack>
                      </Grid>
                      <Grid item xs={6} display="flex" justifyContent="flex-end">
                        <Box style={{borderRadius: 5, height: "100px", width: "140px", overflow: "hidden"}}>
                          <img src={item.image} alt={item.name}
                               style={{objectFit: "cover", height: "100px", width: "140px"}}/>
                        </Box>
                      </Grid>
                    </Grid>
                    {idx === entry.items.length - 1 ? '' : <Divider sx={{width: "100%", margin: "12px 0"}}/>}
                  </>
                );
              })}
            </div>
          })}
        </Grid>
      </div>
    </>
  )
}