
import {Route, Switch} from "react-router-dom";
import Menu from "./Menu";
import Landing from "./Landing";
import GuestMenu from "./GuestMenu";

function App() {
  return (
    <Switch>
        <Route exact path="/" component={Landing}/>
      <Route exact path="/:accessId" component={Menu}/>
      <Route exact path="/g/:restaurantId" component={GuestMenu}/>
    </Switch>
  );
}

export default App;