import './App.css';
import axios from "axios";
import {useEffect, useState} from "react";
import {Box, CircularProgress, Divider, Grid, Stack} from "@mui/material";
import styled from "@emotion/styled";
import AlertDialogSlide from "./AddItemDialog";
import * as React from "react";
import OrderDialog from "./OrderDialog";
import logo from './img/logo.png';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import RoomServiceOutlinedIcon from '@mui/icons-material/RoomServiceOutlined';
import AssistanceDialog from "./AssistanceDialog";
import PaymentDialog from "./PaymentDialog";
import {useLocation} from "react-router-dom";

export default function Menu() {

  const [accessId, setAccessId] = useState(null);
  const [restaurantId, setRestaurantId] = useState(null);
  const [tableId, setTableId] = useState(null);
  const [menu, setMenu] = useState([]);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [validAccess, setValidAccess] = useState(false);
  const [comment, setComment] = useState("");

  const getMenu = (restaurantId) => {
    axios.get(`https://neomenu-backend.fly.dev/restaurants/${restaurantId}/categories`)
      .then(categories => {
        let promises = [];
        categories.data.data.map(c => {
          promises.push(new Promise((resolve, reject) => {
            axios.get(`https://neomenu-backend.fly.dev/restaurants/${restaurantId}/categories/${c._id}/items`)
              .then(items => {
                resolve({category: c, items: items.data.data});
              })
              .catch(e => {
                reject(e);
              });
          }));
        });
        Promise.all(promises)
          .then(items => {
            setMenu(items);
          })
      });
  };

  const validateAccessId = (accessId) => {
    return axios.get(`https://neomenu-backend.fly.dev/accesses/${accessId}/validate`);
  }

  const location = useLocation();

  useEffect(() => {
    let aId = location.pathname.replace('/', '');
    setAccessId(aId);
    validateAccessId(aId)
      .then(async result => {
        let data = result.data.data;
        setValidAccess(data.valid);
        setRestaurantId(data.restaurantId);
        setTableId(data.tableId);
        await getMenu(data.restaurantId)
      })
      .catch(e => {
        console.log(e.toString())
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const ItemName = styled.span`
    color: black;
    font-size: 17px;
    font-weight: bold;
  `
  const ItemDescription = styled.span`
    color: darkgrey;
    font-size: 14px;
    margin-top: 10px;
  `
  const ItemPrice = styled.span`
    color: black;
    margin-top: 10px;
  `

  const CategoryTitle = styled.p`
    color: black;
    font-weight: bold;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 10px;
  `

  const [addItemDialogOpen, setAddItemDialogOpen] = useState(false);
  const [orderDialogOpen, setOrderDialogOpen] = useState(false);
  const [assistanceDialogOpen, setAssistanceDialogOpen] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);

  const handleClickOpenAddItemDialog = (item) => {
    if (!validAccess) return;
    setClickedItem(item);
    setAddItemDialogOpen(true);
  };

  const handleCloseAddItemDialog = () => {
    setAddItemDialogOpen(false);
  };

  const handleClickOpenOrderDialog = () => {
    setOrderDialogOpen(true);
  };

  const handleCloseOrderDialog = () => {
    setOrderDialogOpen(false);
  };

  const handleClickOpenAssistanceDialog = () => {
    setAssistanceDialogOpen(true);
  };

  const handleCloseAssistanceDialog = () => {
    setAssistanceDialogOpen(false);
  };

  const handleClickOpenPaymentDialog = () => {
    setPaymentDialogOpen(true);
  };

  const handleClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
  };

  const addItemUnit = (item) => {
    let updatedRequests = [...requests];
    let idx = updatedRequests.findIndex(r => r.item._id === item._id);
    if (idx > -1) {
      updatedRequests[idx].quantity += 1;
    } else {
      updatedRequests.push({item: item, quantity: 1});
    }
    setRequests(updatedRequests);
  }

  const removeItemUnit = (item) => {
    let updatedRequests = [...requests];
    let idx = updatedRequests.findIndex(r => r.item._id === item._id);
    if (idx > -1) {
      updatedRequests[idx].quantity -= 1;
      updatedRequests = updatedRequests.filter(r => r.quantity > 0); // quantity got to 0? remove it
    }
    setRequests(updatedRequests);
  }

  const OrderButton = styled.div`
    position: absolute;
    bottom: 0;
    font-size: 22px;
    color: white;
    font-weight: bold;
    background: black;
    padding: 22px 0;
    width: 100%;
    text-align: center;
  `

  const TopButton = styled.div`
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background: #eee;
    display: inline-flex;
    border-radius: 6px;
    color: black;
  `

  const clearRequests = () => {
    setRequests([]);
  }

  if (loading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{minHeight: '100vh'}}
      >
        <Grid item xs={3}>
          <CircularProgress style={{color: "#1ea59a"}}/>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <div id="header">
        <Grid
          justifyContent="space-between"
          container
          alignItems="center"
          justify="center"
          px={2}
          py={1}
        >
          <Grid item>
            <img src={logo} alt="logo" height={18} style={{marginTop: "6px"}}/>
          </Grid>

          {validAccess ? (
            <Grid item>
              {/*<TopButton><Flag code="pt" height="16" style={{padding: "4px"}}/></TopButton>*/}
              {/*<div style={{display: "inline-block", marginRight: "5px"}}/>*/}
              <TopButton onClick={handleClickOpenAssistanceDialog}><RoomServiceOutlinedIcon/></TopButton>
              <div style={{display: "inline-block", marginRight: "5px"}}/>
              <TopButton onClick={handleClickOpenPaymentDialog}><ReceiptLongOutlinedIcon/></TopButton>
            </Grid>
          ) : (
            <Grid item
                  style={{
                    width: "50%",
                    background: "#FFCA00",
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "6px"
                  }}>
              Viewing as guest
            </Grid>
          )}
        </Grid>

        <Divider/>
        <Stack style={{
          color: "black",
          fontSize: "18px",
          display: 'flex',
          flexDirection: 'row',
          padding: "0 5px",
          maxWidth: "100%",
          overflowY: "none",
          overflowX: "auto"
        }}>
          {menu.map((entry, idx) => <><span
            style={{
              margin: "0 14px",
              height: '38px',
              display: 'inline-flex',
              alignItems: 'center',
              whiteSpace: "nowrap"
            }}
            onClick={() => {
              // Clicking an already selected category does nothing
              // TODO: do this properly, allow multiple clicks on the same category
              if (window.location.hash.replace('#', '') !== entry.category.name) {
                // Clicking a category scrolls to it
                window.location.hash = entry.category.name;
                window.scrollBy(0, -96);
              }
            }}>{entry.category.name}</span>
            {idx === menu.length - 1 ? '' : <Divider orientation="vertical" variant="middle" flexItem/>}</>
          )}
        </Stack>
        <Divider/>
      </div>

      <div id="items">
        <Grid container px={2} sx={{mt: "8px"}}>
          {menu.map(entry => {
            return <div style={{width: "100%", marginBottom: "15px"}}>
              <CategoryTitle id={entry.category.name}>{entry.category.name}</CategoryTitle>
              {entry.items.map((item, idx) => {
                return (
                  <>
                    <Grid container onClick={() => handleClickOpenAddItemDialog(item)} style={{minHeight: "100px"}}>
                      <Grid item xs={6}>
                        <Stack>
                          <ItemName>{item.name}</ItemName>
                          {item.description ? <ItemDescription>{item.description}</ItemDescription> : ''}
                          <ItemPrice>${item.price.toFixed(2)}</ItemPrice>
                        </Stack>
                      </Grid>
                      <Grid item xs={6} display="flex" justifyContent="flex-end">
                        {item.image && (
                          <Box style={{borderRadius: 5, height: "100px", width: "140px", overflow: "hidden"}}>
                            <img src={item.image} alt={item.name}
                                 style={{objectFit: "cover", height: "100px", width: "140px"}}/>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    {idx === entry.items.length - 1 ? '' : <Divider sx={{width: "100%", margin: "12px 0"}}/>}
                  </>
                );
              })}
            </div>
          })}
        </Grid>
      </div>

      <div id="footer">
        {requests.length > 0 ?
          <OrderButton onClick={handleClickOpenOrderDialog}>Your order ({requests.length})</OrderButton> : ""}
      </div>
      <AlertDialogSlide requests={requests} open={addItemDialogOpen} clickedItem={clickedItem}
                        handleClose={handleCloseAddItemDialog}
                        addItemUnit={addItemUnit} removeItemUnit={removeItemUnit}/>
      <OrderDialog restaurantId={restaurantId} accessId={accessId} tableId={tableId} open={orderDialogOpen}
                   handleClose={handleCloseOrderDialog} requests={requests} menu={menu}
                   clearRequests={clearRequests}/>
      <AssistanceDialog accessId={accessId} restaurantId={restaurantId} tableId={tableId} open={assistanceDialogOpen}
                        handleClose={handleCloseAssistanceDialog}/>
      <PaymentDialog accessId={accessId} restaurantId={restaurantId} tableId={tableId} open={paymentDialogOpen}
                     handleClose={handleClosePaymentDialog}/>
    </>
  )
}