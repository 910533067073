import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import styled from "@emotion/styled";
import TollIcon from '@mui/icons-material/Toll';
import {useState} from "react";
import {Alert, Divider, Grid, InputBase, Paper, Snackbar, Stack} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentDialog(props) {

  const [other, setOther] = useState(false);
  const [customPaymentType, setCustomPaymentType] = useState("");

  const BlackButton = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: black;
    color: white;
    height: 39px;
    padding: 0 12px;
    font-weight: bold;
    border-radius: 5px;
  `

  const GreyButton = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: #eee;
    color: black;
    padding: 10px 12px;
    font-weight: bold;
    border-radius: 5px;
  `

  const [openAlert, setOpenAlert] = React.useState(false);

  const updateStatusToPayment = (paymentType) => {
    const configuration = {
      method: "PATCH",
      url: `https://neomenu-backend.fly.dev/restaurants/${props.restaurantId}/tables/${props.tableId}/bill`,
      data: {
        accessId: props.accessId,
        message: paymentType,
      },
    };
    return axios(configuration)
      .then(result => {
      })
      .catch(e => {
        console.log(e.toString())
      });
  }

  const handleClick = async (paymentType) => {
    await updateStatusToPayment(paymentType);
    props.handleClose();
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {setOther(false); props.handleClose()}}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle>Finish meal</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{marginBottom: "6px"}}>Choose your payment method.</div>
            <Grid container spacing={1}>
              <Grid item>
                <BlackButton onClick={() => handleClick("cash")}><PaymentsIcon sx={{mr: 1}}/>Cash</BlackButton>
              </Grid>
              <Grid item>
                <BlackButton onClick={() => handleClick("card")}><CreditCardIcon sx={{mr: 1}}/>Card</BlackButton>
              </Grid>
              <Grid item>
                <BlackButton onClick={() => setOther(true)}>Other</BlackButton>
              </Grid>
              {other ?
                <Paper
                  component="form"
                  sx={{mt: 2, p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%"}}
                >
                  <InputBase
                    sx={{ml: 1, flex: 1}}
                    placeholder="Payment method"
                    value={customPaymentType}
                    onChange={(e) => setCustomPaymentType(e.target.value)}
                  />
                  <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>
                  <IconButton onClick={handleClick} sx={{p: '10px', color: 'black'}} aria-label="directions">
                    <SendIcon onClick={() => handleClick(customPaymentType)} />
                  </IconButton>
                </Paper>
                : ''}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <GreyButton>Cancel</GreyButton>
        </DialogActions>
      </Dialog>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%', fontWeight: 'bold' }}>
          A waiter has been notified.
        </Alert>
      </Snackbar>
    </div>
);
}
